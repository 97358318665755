import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Component, Input, Inject, EventEmitter, Output, NgModule } from '@angular/core';
import { takeUntil, switchMap, take, map, filter } from 'rxjs/operators';
import * as i1$1 from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { ReplaySubject, Subject, timer, Subscription, of } from 'rxjs';
import * as i1 from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import * as i3 from '@angular/common';
import { CommonModule } from '@angular/common';
import * as i4 from '@angular/material/button';
import { MatButtonModule } from '@angular/material/button';
import * as i2 from '@sparbanken-syd/sparbanken-syd-qr';
import { QrModule } from '@sparbanken-syd/sparbanken-syd-qr';
import * as i4$1 from '@angular/material/progress-spinner';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

/**
 * this is the Angular 6 way of getting access to the window.
 *
 * Admittedly, I do not understand shit of this!
 *
 * However since we use "window" to change the location and
 * we to be able to mock it so that it does not actually navigate
 * in code when called we need to "inject" window into the application.
 *
 */
/**
 * Defines and exports an injection token that replaces the browser
 * window object.
 */
function SignDialogComponent_span_3_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "span");
    i0.ɵɵtext(1);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r0 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵtextInterpolate(ctx_r0.statusText);
  }
}
function SignDialogComponent_div_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵelement(1, "spb-qr", 4);
    i0.ɵɵelementEnd();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("orderRef", ctx_r1.orderRef);
  }
}
const _c0 = () => ({
  status: "cancel"
});
function WaitDialogComponent_mat_dialog_actions_4_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "mat-dialog-actions")(1, "button", 3);
    i0.ɵɵtext(2, "St\xE4ng");
    i0.ɵɵelementEnd()();
  }
}
function BankIdComponent_div_0_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 2);
    i0.ɵɵelement(1, "mat-spinner");
    i0.ɵɵelementEnd();
  }
}
function BankIdComponent_div_1_div_1_Template(rf, ctx) {
  if (rf & 1) {
    i0.ɵɵelementStart(0, "div", 6)(1, "div");
    i0.ɵɵtext(2);
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(3, "div", 7);
    i0.ɵɵtext(4);
    i0.ɵɵelementEnd()();
  }
  if (rf & 2) {
    const ctx_r2 = i0.ɵɵnextContext(2);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate(ctx_r2.errorMessage);
    i0.ɵɵadvance(2);
    i0.ɵɵtextInterpolate1("(", ctx_r2.errorDetail, ")");
  }
}
function BankIdComponent_div_1_Template(rf, ctx) {
  if (rf & 1) {
    const _r4 = i0.ɵɵgetCurrentView();
    i0.ɵɵelementStart(0, "div");
    i0.ɵɵtemplate(1, BankIdComponent_div_1_div_1_Template, 5, 2, "div", 3);
    i0.ɵɵelementStart(2, "div", 4)(3, "button", 5);
    i0.ɵɵlistener("click", function BankIdComponent_div_1_Template_button_click_3_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r3 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r3.startBankId(false));
    });
    i0.ɵɵtext(4, " BankID p\xE5 denna enhet ");
    i0.ɵɵelementEnd();
    i0.ɵɵelementStart(5, "button", 5);
    i0.ɵɵlistener("click", function BankIdComponent_div_1_Template_button_click_5_listener() {
      i0.ɵɵrestoreView(_r4);
      const ctx_r5 = i0.ɵɵnextContext();
      return i0.ɵɵresetView(ctx_r5.startBankId(true));
    });
    i0.ɵɵtext(6, " BankID p\xE5 annan enhet ");
    i0.ɵɵelementEnd()()();
  }
  if (rf & 2) {
    const ctx_r1 = i0.ɵɵnextContext();
    i0.ɵɵadvance(1);
    i0.ɵɵproperty("ngIf", ctx_r1.errorMessage);
  }
}
const WINDOW = new InjectionToken('window');
/**
 * The provider
 */
const windowProvider = {
  provide: WINDOW,
  useFactory: () => window
};
/**
 * The exported provider(s). We only have one. This is "provided"
 * in app.component.
 */
const WINDOW_PROVIDERS = [windowProvider];
class BankIdService {
  /**
   * We need a client
   */
  constructor(httpClient) {
    this.httpClient = httpClient;
    /**
     * Has to be set from the outside
     */
    this.bankIdUrl = '';
    this.pCurrentUser$ = new ReplaySubject(1);
    this.currentUser$ = this.pCurrentUser$.asObservable();
  }
  /**
   * Start auth session.
   */
  startLogin() {
    let headers = new HttpHeaders();
    headers = headers.set('X-SSO-SITE', 'sso-site');
    const url = `${this.bankIdUrl}/login/start`;
    const data = {
      domain: this.bankIdUrl,
      type: 'auth',
      scope: 'auth'
    };
    return this.httpClient.put(url, data, {
      headers
    });
  }
  /**
   * Start sign session
   * @param userRef
   */
  startSign(userRef) {
    const url = `${this.bankIdUrl}/login/start/?userRef=${userRef}`;
    return this.httpClient.get(url);
  }
  /**
   * Collect, typically every two seconds.
   *
   * @param orderRef - The BankID order reference
   * @param userRef - A reference used for a signing case
   */
  collect(orderRef, userRef) {
    const url = `${this.bankIdUrl}/login/collect?orderRef=${orderRef}`;
    //Sign
    if (userRef) {
      return this.httpClient.get(`${url}&userRef=${userRef}`);
    } else {
      //Login
      let headers = new HttpHeaders();
      headers = headers.set('X-SSO-SITE', 'sso-site');
      return this.httpClient.get(url, {
        headers,
        withCredentials: true
      });
    }
  }
  /**
   * Cancel the request
   */
  cancel(orderRef) {
    const url = `${this.bankIdUrl}/login/cancel?orderRef=${orderRef}`;
    return this.httpClient.get(url);
  }
  /**
   * Get image, which represents a qrAuthCode, from database
   */
  getImage(orderRef) {
    const url = `${this.bankIdUrl}/login/image?orderRef=${orderRef}`;
    return this.httpClient.get(url);
  }
  static {
    this.ɵfac = function BankIdService_Factory(t) {
      return new (t || BankIdService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: BankIdService,
      factory: BankIdService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class QrCodeComponent {
  constructor(bankIdService) {
    this.bankIdService = bankIdService;
    /**
     * The data we need for calling get image
     */
    this.orderRef = '';
    /**
     * Data is what we send to the QR code component
     */
    this.data = '';
    /**
     * Used to cancel timer
     */
    this.cancel = new Subject();
  }
  ngAfterViewInit() {
    timer(0, 1000)
    /**
     * takeUntil the subject emits...
     */.pipe(takeUntil(this.cancel), switchMap(() => this.bankIdService.getImage(this.orderRef))).subscribe({
      next: qrAuthCode => this.data = qrAuthCode
    });
  }
  ngOnDestroy() {
    this.cancel.next(null);
  }
  static {
    this.ɵfac = function QrCodeComponent_Factory(t) {
      return new (t || QrCodeComponent)(i0.ɵɵdirectiveInject(BankIdService));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: QrCodeComponent,
      selectors: [["spb-qr"]],
      inputs: {
        orderRef: "orderRef"
      },
      decls: 10,
      vars: 1,
      consts: [[3, "data"]],
      template: function QrCodeComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "ol")(1, "li");
          i0.ɵɵtext(2, "\xD6ppna appen f\xF6r BankID.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(3, "li");
          i0.ɵɵtext(4, "Tryck p\xE5 QR-kod/Skanna QR kod i appen f\xF6r BankID.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "li");
          i0.ɵɵtext(6, "Rikta kameran mot QR-koden.");
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(7, "li");
          i0.ɵɵtext(8, "F\xF6lj instruktionerna i appen.");
          i0.ɵɵelementEnd()();
          i0.ɵɵelement(9, "spb-qr-code", 0);
        }
        if (rf & 2) {
          i0.ɵɵadvance(9);
          i0.ɵɵproperty("data", ctx.data);
        }
      },
      dependencies: [i2.QRComponent],
      styles: ["ol[_ngcontent-%COMP%]{margin:0 0 28px}li[_ngcontent-%COMP%]{font-size:1.3rem;line-height:1.6rem;margin-bottom:8px}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(QrCodeComponent, [{
    type: Component,
    args: [{
      selector: 'spb-qr',
      template: "<ol>\n  <li>\u00D6ppna appen f\u00F6r BankID.</li>\n  <li>Tryck p\u00E5 QR-kod/Skanna QR kod i appen f\u00F6r BankID.</li>\n  <li>Rikta kameran mot QR-koden.</li>\n  <li>F\u00F6lj instruktionerna i appen.</li>\n</ol>\n<spb-qr-code [data]=\"data\"></spb-qr-code>\n",
      styles: ["ol{margin:0 0 28px}li{font-size:1.3rem;line-height:1.6rem;margin-bottom:8px}\n"]
    }]
  }], () => [{
    type: BankIdService
  }], {
    orderRef: [{
      type: Input
    }]
  });
})();
class SignDialogComponent {
  constructor(dialogRef, bankIdService, window, data) {
    this.dialogRef = dialogRef;
    this.bankIdService = bankIdService;
    this.window = window;
    this.data = data;
    /**
     * Temp for test
     */
    this.statusText = '';
    /**
     */
    this.errorMessage = '';
    /**
     * Other device, that is show QR
     */
    this.other = false;
    this.orderRef = '';
    /**
     * The subscription to check for BankID completion.
     */
    this.countDown = new Subscription();
    this.userTexts = {
      userCancel: 'Avbrutet i BankID applikationen',
      outstandingTransaction: 'Försöker starta BankID-appen',
      userSign: 'Skriv in din säkerhetskod i BankID-appen och välj Identifiera'
    };
  }
  /**
   *
   */
  loginOnThisDevice() {
    if (!this.other) {
      const loginOnThisDeviceLink = `bankid:///?autostarttoken=${this.data.startResponse.autoStartToken}&redirect=null`;
      /**
       * We should figure out a way to detect failures...
       */
      return of(this.window.open(loginOnThisDeviceLink, '_parent'));
    } else {
      return of({});
    }
  }
  ngOnInit() {
    this.other = this.data.other;
    this.orderRef = this.data.startResponse.orderRef;
    this.countDown = this.loginOnThisDevice().pipe(switchMap(() => timer(0, 2000)), take(60), switchMap(() => this.bankIdService.collect(this.orderRef, this.data.userRef)), map(response => {
      this.statusText = this.userTexts[response.hintCode];
      if (response.hintCode === 'userCancel') {
        this.statusText = this.userTexts[response.hintCode];
        this.dialogRef.close({
          status: 'cancel'
        });
        this.cancel();
      }
      if (response.accessToken) {
        this.dialogRef.close({
          status: 'ok',
          accessToken: response.accessToken
        });
        return true;
      }
      return false;
    }), filter(res => res), map(() => this.countDown.unsubscribe())).subscribe({
      complete: () => {
        this.dialogRef.close({
          status: 'cancel'
        });
      },
      error: error => {
        this.countDown.unsubscribe();
        this.dialogRef.close({
          status: 'error',
          errorDetail: error.error.errorMessage,
          message: error.message
        });
      }
    });
  }
  cancel() {
    this.countDown.unsubscribe(); // If user press cancel we need to unsubscribe
    this.bankIdService.cancel(this.data.startResponse.orderRef).subscribe();
    this.dialogRef.close({
      status: 'cancel'
    });
  }
  static {
    this.ɵfac = function SignDialogComponent_Factory(t) {
      return new (t || SignDialogComponent)(i0.ɵɵdirectiveInject(i1$1.MatDialogRef), i0.ɵɵdirectiveInject(BankIdService), i0.ɵɵdirectiveInject(WINDOW), i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: SignDialogComponent,
      selectors: [["spb-sign-dialog"]],
      decls: 8,
      vars: 7,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [4, "ngIf"], ["mat-stroked-button", "", 3, "matDialogClose", "click"], [3, "orderRef"]],
      template: function SignDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtemplate(3, SignDialogComponent_span_3_Template, 2, 1, "span", 2)(4, SignDialogComponent_div_4_Template, 2, 1, "div", 2);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(5, "mat-dialog-actions")(6, "button", 3);
          i0.ɵɵlistener("click", function SignDialogComponent_Template_button_click_6_listener() {
            return ctx.cancel();
          });
          i0.ɵɵtext(7, "Avbryt ");
          i0.ɵɵelementEnd()();
        }
        if (rf & 2) {
          i0.ɵɵadvance(1);
          i0.ɵɵtextInterpolate(ctx.data.title);
          i0.ɵɵadvance(1);
          i0.ɵɵstyleMap("padding: 20px 24px 20px 24px");
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("ngIf", !ctx.other);
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("ngIf", ctx.other);
          i0.ɵɵadvance(2);
          i0.ɵɵproperty("matDialogClose", i0.ɵɵpureFunction0(6, _c0));
        }
      },
      dependencies: [i3.NgIf, i1$1.MatDialogClose, i1$1.MatDialogTitle, i1$1.MatDialogActions, i1$1.MatDialogContent, i4.MatButton, QrCodeComponent],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-sign-dialog',
      template: "<div matDialogTitle>{{data.title}}</div>\n<div matDialogContent [style]=\"'padding: 20px 24px 20px 24px'\">\n  <span *ngIf=\"!other\">{{statusText}}</span>\n  <div *ngIf=\"other\">\n    <spb-qr [orderRef]=\"orderRef\"></spb-qr>\n  </div>\n</div>\n<mat-dialog-actions>\n  <button (click)=\"cancel()\"\n          [matDialogClose]=\"{status: 'cancel'}\"\n          mat-stroked-button\n  >Avbryt\n  </button>\n</mat-dialog-actions>\n"
    }]
  }], () => [{
    type: i1$1.MatDialogRef
  }, {
    type: BankIdService
  }, {
    type: Window,
    decorators: [{
      type: Inject,
      args: [WINDOW]
    }]
  }, {
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();
class WaitDialogComponent {
  constructor(data) {
    this.data = data;
  }
  static {
    this.ɵfac = function WaitDialogComponent_Factory(t) {
      return new (t || WaitDialogComponent)(i0.ɵɵdirectiveInject(MAT_DIALOG_DATA));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: WaitDialogComponent,
      selectors: [["spb-wait-dialog"]],
      decls: 5,
      vars: 3,
      consts: [["matDialogTitle", ""], ["matDialogContent", ""], [4, "ngIf"], ["color", "primary", "mat-raised-button", ""]],
      template: function WaitDialogComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵelementStart(0, "div", 0);
          i0.ɵɵtext(1);
          i0.ɵɵelementEnd();
          i0.ɵɵelementStart(2, "div", 1);
          i0.ɵɵtext(3);
          i0.ɵɵelementEnd();
          i0.ɵɵtemplate(4, WaitDialogComponent_mat_dialog_actions_4_Template, 3, 0, "mat-dialog-actions", 2);
        }
        if (rf & 2) {
          i0.ɵɵadvance(1);
          i0.ɵɵtextInterpolate(ctx.data.title);
          i0.ɵɵadvance(2);
          i0.ɵɵtextInterpolate1(" ", ctx.data.message, " ");
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("ngIf", ctx.data.close);
        }
      },
      dependencies: [i3.NgIf, i1$1.MatDialogTitle, i1$1.MatDialogActions, i1$1.MatDialogContent, i4.MatButton],
      encapsulation: 2
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(WaitDialogComponent, [{
    type: Component,
    args: [{
      selector: 'spb-wait-dialog',
      template: `
    <div matDialogTitle>{{data.title}}</div>
    <div matDialogContent>
      {{data.message}}
    </div>
    <mat-dialog-actions *ngIf="data.close">
      <button color="primary" mat-raised-button>Stäng</button>
    </mat-dialog-actions>
  `
    }]
  }], () => [{
    type: undefined,
    decorators: [{
      type: Inject,
      args: [MAT_DIALOG_DATA]
    }]
  }], null);
})();

/**
 * Calls BankID as necessary and when done (or failed)
 * it emits an event that (someone) can listen to.
 */
class BankIdComponent {
  /**
   * Default constructor
   */
  constructor(bankIdService, dialog) {
    this.bankIdService = bankIdService;
    this.dialog = dialog;
    /**
     * The access token after successful authentication.
     * It is a base64 encoded jwt token for your pleasure
     */
    this.accessToken = new EventEmitter();
    /**
     * The complete or relative url to use. If nothing set '/service' is assumed.
     */
    this.bankIdUrl = '';
    /**
     * The domain from which the user is directed
     */
    this.domain = '';
    /**
     * The user ref and the backend which is used for signing (for example blanco backend)
     */
    this.userRef = '';
    /**
     * A general error message
     */
    this.errorMessage = '';
    /**
     * Details of the error.
     */
    this.errorDetail = '';
    /**
     * A spinner that is displayed if the user has received an OK from bankID
     */
    this.spinner = false;
    this.data = {
      actionText: '',
      doneText: '',
      other: false,
      startResponse: {
        orderRef: '',
        qrStartToken: '',
        autoStartToken: '',
        authArray: []
      },
      title: '',
      type: ''
    };
  }
  /**
   * Set wait state and try to log in.
   *
   * @param other - 'other' is login on 'other device', id est start here or show qr.
   */
  startBankId(other) {
    this.bankIdService.bankIdUrl = this.bankIdUrl;
    const startResponse = {
      authArray: [],
      autoStartToken: '',
      orderRef: '',
      qrStartToken: ''
    };
    //Login
    this.data = {
      type: 'login',
      title: 'Legitimera',
      actionText: 'Skriv in din säkerhetskod i BankID-appen och välj Legitimera.',
      doneText: 'Legitimering klar. Ha en fin dag!',
      startResponse,
      other
    };
    //Sign
    if (this.userRef) {
      this.data.type = 'sign';
      this.data.title = 'Signera';
      this.data.actionText = 'Skriv in din säkerhetskod i BankID-appen och välj Skriv under.';
      this.data.doneText = 'Signering klar';
      this.data.userRef = this.userRef;
    }
    this.openDialog();
  }
  openDialog() {
    this.errorMessage = '';
    const waitRef = this.dialog.open(WaitDialogComponent, {
      disableClose: true,
      data: {
        title: 'Förbereder BankID',
        message: 'Vi förbereder för BankID, var god dröj...'
      }
    });
    waitRef.afterOpened().pipe(switchMap(() => {
      if (this.userRef) {
        return this.bankIdService.startSign(this.userRef);
      } else {
        return this.bankIdService.startLogin();
      }
    }), switchMap(startResponse => {
      this.data.startResponse = startResponse;
      this.signDialogRef = this.dialog.open(SignDialogComponent, {
        width: '90%',
        maxWidth: '400px',
        disableClose: true,
        data: this.data
      });
      waitRef.close();
      return this.signDialogRef.afterClosed();
    })).subscribe({
      next: res => {
        if (res.status === 'ok') {
          this.spinner = true;
        }
        if (res.status === 'bankIDTimeout') {
          this.errorMessage = 'Tidsgränsen tog slut, vänligen försök igen';
        }
        if (res.accessToken) {
          this.accessToken.emit(res.accessToken);
        }
        if (res.status === 'error') {
          this.errorMessage = 'Kunde inte starta BankID';
          this.errorDetail = res.errorDetail;
        }
      },
      error: err => {
        waitRef.close();
        this.errorMessage = 'Kunde inte starta BankID';
        this.errorDetail = err.error.errorMessage;
      }
    });
  }
  static {
    this.ɵfac = function BankIdComponent_Factory(t) {
      return new (t || BankIdComponent)(i0.ɵɵdirectiveInject(BankIdService), i0.ɵɵdirectiveInject(i1$1.MatDialog));
    };
  }
  static {
    this.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
      type: BankIdComponent,
      selectors: [["spb-bankid"]],
      inputs: {
        bankIdUrl: "bankIdUrl",
        domain: "domain",
        userRef: "userRef"
      },
      outputs: {
        accessToken: "accessToken"
      },
      decls: 2,
      vars: 2,
      consts: [["class", "mat-spinner", 4, "ngIf"], [4, "ngIf"], [1, "mat-spinner"], ["class", "spb-error", 4, "ngIf"], [1, "buttons"], ["color", "primary", "mat-raised-button", "", 3, "click"], [1, "spb-error"], [1, "error-detail"]],
      template: function BankIdComponent_Template(rf, ctx) {
        if (rf & 1) {
          i0.ɵɵtemplate(0, BankIdComponent_div_0_Template, 2, 0, "div", 0)(1, BankIdComponent_div_1_Template, 7, 1, "div", 1);
        }
        if (rf & 2) {
          i0.ɵɵproperty("ngIf", ctx.spinner);
          i0.ɵɵadvance(1);
          i0.ɵɵproperty("ngIf", !ctx.spinner);
        }
      },
      dependencies: [i3.NgIf, i4$1.MatProgressSpinner, i4.MatButton],
      styles: [".buttons[_ngcontent-%COMP%]{justify-content:center;display:flex}.buttons[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin:0 10px}.mat-spinner[_ngcontent-%COMP%]{display:flex;justify-content:center}.error-message[_ngcontent-%COMP%]{color:#be0005;margin:20px 0 0;text-align:center}.spb-error[_ngcontent-%COMP%]{background-color:#be0005;color:#f3f2f1;font-weight:700;margin:1em auto;padding:.7em;text-align:center;width:90%}@media only screen and (max-width: 500px){.buttons[_ngcontent-%COMP%]{align-items:center;display:flex;flex-direction:column;justify-content:center}.buttons[_ngcontent-%COMP%]   button[_ngcontent-%COMP%]{margin:10px 0}}"]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdComponent, [{
    type: Component,
    args: [{
      selector: 'spb-bankid',
      template: "<div *ngIf=\"spinner\" class=\"mat-spinner\">\n  <mat-spinner></mat-spinner>\n</div>\n\n<div *ngIf=\"!spinner\">\n  <div *ngIf=\"errorMessage\" class=\"spb-error\">\n    <div>{{errorMessage}}</div>\n    <div class=\"error-detail\">({{errorDetail}})</div>\n  </div>\n  <div class=\"buttons\">\n    <button\n      (click)=\"startBankId(false)\" color=\"primary\"\n      mat-raised-button>\n      BankID p\u00E5 denna enhet\n    </button>\n    <button\n      (click)=\"startBankId(true)\" color=\"primary\"\n      mat-raised-button>\n      BankID p\u00E5 annan enhet\n    </button>\n  </div>\n</div>\n",
      styles: [".buttons{justify-content:center;display:flex}.buttons button{margin:0 10px}.mat-spinner{display:flex;justify-content:center}.error-message{color:#be0005;margin:20px 0 0;text-align:center}.spb-error{background-color:#be0005;color:#f3f2f1;font-weight:700;margin:1em auto;padding:.7em;text-align:center;width:90%}@media only screen and (max-width: 500px){.buttons{align-items:center;display:flex;flex-direction:column;justify-content:center}.buttons button{margin:10px 0}}\n"]
    }]
  }], () => [{
    type: BankIdService
  }, {
    type: i1$1.MatDialog
  }], {
    accessToken: [{
      type: Output
    }],
    bankIdUrl: [{
      type: Input
    }],
    domain: [{
      type: Input
    }],
    userRef: [{
      type: Input
    }]
  });
})();
class SingleSignOnService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  /**
   * Get cookie token from the database, based on cookie in header
   */
  getToken(bankidUrl, domain) {
    let headers = new HttpHeaders();
    headers = headers.set('X-SPB-SSO-DOMAIN', domain);
    const url = `${bankidUrl}/token`;
    return this.httpClient.get(url, {
      headers,
      withCredentials: true
    });
  }
  /**
   * Delete cookie token from the database, based on cookie in header
   */
  deleteToken(bankidUrl) {
    const url = `${bankidUrl}/token`;
    return this.httpClient.delete(url, {
      withCredentials: true
    });
  }
  static {
    this.ɵfac = function SingleSignOnService_Factory(t) {
      return new (t || SingleSignOnService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: SingleSignOnService,
      factory: SingleSignOnService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SingleSignOnService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();

/**
 * A couple of helper functions to validate the a JWT and a function
 * that returns the current user.
 */
class HelperService {
  constructor(httpClient) {
    this.httpClient = httpClient;
  }
  /**
   * Returns the saem toke that is passed in, or null.
   *
   * @param token - A JWT token
   * @returns - The valid token or null
   */
  static ValidateToken(token) {
    if (!token) {
      return null;
    }
    try {
      const payload = JSON.parse(window.atob(token.split('.')[1]));
      return payload.exp > new Date().getTime() ? token : null;
    } catch (_e) {
      /**
       * Catch and ignore any problems with malformed tokens
       */
      return null;
    }
  }
  /**
   * Gets the payload from a JWT, it first validates it (the timestamp, not the signature)
   * before returning the payload as an object
   * @param token - The complete token, base64 encoded (a.ey.b)
   *
   * @constructor
   */
  static GetTokenPayload(token) {
    const verified = HelperService.ValidateToken(token);
    return verified ? JSON.parse(window.atob(verified.split('.')[1])) : null;
  }
  /**
   * Fetch the current user
   *
   * @param baseUrl - The URL including https:// etc where the user service is located
   */
  getCurrentUser(baseUrl) {
    const url = `${baseUrl}/users/self`;
    return this.httpClient.get(url);
  }
  static {
    this.ɵfac = function HelperService_Factory(t) {
      return new (t || HelperService)(i0.ɵɵinject(i1.HttpClient));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: HelperService,
      factory: HelperService.ɵfac,
      providedIn: 'root'
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(HelperService, [{
    type: Injectable,
    args: [{
      providedIn: 'root'
    }]
  }], () => [{
    type: i1.HttpClient
  }], null);
})();
class BankIdModule {
  static {
    this.ɵfac = function BankIdModule_Factory(t) {
      return new (t || BankIdModule)();
    };
  }
  static {
    this.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
      type: BankIdModule
    });
  }
  static {
    this.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
      providers: [WINDOW_PROVIDERS],
      imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule, MatButtonModule, QrModule]
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BankIdModule, [{
    type: NgModule,
    args: [{
      declarations: [BankIdComponent, WaitDialogComponent, SignDialogComponent, QrCodeComponent],
      imports: [CommonModule, MatProgressSpinnerModule, MatDialogModule, MatButtonModule, QrModule],
      exports: [BankIdComponent],
      providers: [WINDOW_PROVIDERS]
    }]
  }], null, null);
})();

/*
 * Public API Surface of bankid
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BankIdComponent, BankIdModule, HelperService, SingleSignOnService };
